export default [
  {
    path: '/materiales/list',
    name: 'materiales-list',
    component: () => import('@/views/almacen/materiales/MaterialesList.vue'),
    meta: {
      access: ['admin'],
      pageTitle: 'Materiales',
      breadcrumb: [
        {
          text: 'Materiales',
          active: true,
        },
      ],
    },
  },
  {
    path: '/materiales/edit/:id',
    name: 'material-edit',
    component: () => import('@/views/almacen/materiales/MaterialEdit.vue'),
    meta: {
      access: ['admin'],
      pageTitle: 'Editar Material',
      breadcrumb: [
        {
          text: 'Lista de Materiales',
          active: false,
          to: '/materiales/list',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/materiales-recepcion/list',
    name: 'materiales-recepcion-list',
    component: () => import('@/views/almacen/materialesRecepcion/MaterialesRecepcionList.vue'),
    meta: {
      access: ['admin'],
      pageTitle: 'Recepción Materiales',
      breadcrumb: [
        {
          text: 'Recepción Materiales',
          active: true,
        },
      ],
    },
  },
  {
    path: '/materiales-recepcion/edit/:id',
    name: 'materiales-recepcion-edit',
    component: () => import('@/views/almacen/materialesRecepcion/MaterialesRecepcionEdit.vue'),
    meta: {
      access: ['admin'],
      pageTitle: 'Editar ',
      breadcrumb: [
        {
          text: 'Lista de Recepcións',
          active: false,
          to: '/materiales-recepcion/list',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pedidos/list',
    name: 'pedidos-list',
    component: () => import('@/views/almacen/pedidos/PedidosList.vue'),
    meta: {
      access: ['admin', 'operario'],
      pageTitle: 'Pedidos',
      breadcrumb: [
        {
          text: 'Pedidos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pedidos/edit/:id',
    name: 'pedido-edit',
    component: () => import('@/views/almacen/pedidos/PedidoForm.vue'),
    meta: {
      access: ['admin', 'operario'],
      pageTitle: 'Editar Pedido',
      breadcrumb: [
        {
          text: 'Lista de Pedidos',
          active: false,
          to: '/pedidos/list',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pedidos-proveedores/list',
    name: 'pedidos-proveedores-list',
    component: () => import('@/views/almacen/pedidosProveedores/PedidosProveedoresList.vue'),
    meta: {
      access: ['admin'],
      pageTitle: 'Pedidos Proveedores',
      breadcrumb: [
        {
          text: 'Pedidos Proveedores',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pedidos-proveedores/edit/:id',
    name: 'pedido-proveedor-edit',
    component: () => import('@/views/almacen/pedidosProveedores/PedidoProveedorForm.vue'),
    meta: {
      access: ['admin'],
      pageTitle: 'Editar Pedido Proveedor',
      breadcrumb: [
        {
          text: 'Lista de Pedidos Proveedores',
          active: false,
          to: '/pedidos-proveedores/list',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/albaranes/list',
    name: 'albaranes-list',
    component: () => import('@/views/almacen/albaranes/AlbaranesList.vue'),
    meta: {
      access: ['admin', 'operario'],
      pageTitle: 'Entrega de material',
      breadcrumb: [
        {
          text: 'Entregas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/historico-materiales/list',
    name: 'historico-materiales-list',
    component: () => import('@/views/almacen/historicoMateriales/HistoricoMaterialesList.vue'),
    meta: {
      access: ['admin', 'operario'],
      pageTitle: 'Histórico de Materiales',
      breadcrumb: [
        {
          text: 'Histórico',
          active: true,
        },
      ],
    },
  },
  {
    path: '/albaranes/edit/:id',
    name: 'albaran-edit',
    component: () => import('@/views/almacen/albaranes/AlbaranForm.vue'),
    meta: {
      access: ['admin', 'operario'],
      pageTitle: 'Entrega de material',
      breadcrumb: [
        {
          text: 'Lista de materiales',
          active: false,
          to: '/albaranes/list',
        },
        {
          text: 'Entregar',
          active: true,
        },
      ],
    },
  },
]
