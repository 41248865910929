import store from '@/store/index'

function isPublic(route) {
  if (route.meta.access) {
    if (route.meta.access.includes('public')) return true
    return false
  }
  return false
}

function isOperario(route) {
  if (route.meta.access) {
    if (route.meta.access.includes('operario')) return true
    return false
  }
  return false
}

function isAdmin(route) {
  if (route.meta.access) {
    if (route.meta.access.includes('admin')) return true
    return false
  }
  return false
}

function isUserAdmin() {
  return store.state.auth.user.fk_role === 1
}

function isUserOperario() {
  return store.state.auth.user.fk_role === 2
}

export default {
  manageRouter: async (to, from, next) => {
    let { isLoggedIn } = store.getters
    const { isRememberMe } = store.getters

    // Call auto login in case user is not logged in and remember me is activated
    if (!isLoggedIn) {
      if (isRememberMe) {
        await store.dispatch('autologin')
        isLoggedIn = store.getters.isLoggedIn

        if (isAdmin(to) && !isOperario(to) && !isUserAdmin()) next({ name: 'error-403' })

        if (isOperario(to) && !isUserOperario() && !isUserAdmin()) next({ name: 'error-403' })
      } else {
        if (window.localStorage.getItem('userData')) store.dispatch('logout')
        if (!isPublic(to)) {
          next({ name: 'login' })
        }
      }
    }

    if (isLoggedIn && to.name === 'login') {
      next({ name: 'home' })
    }

    if (isAdmin(to) && !isOperario(to) && !isUserAdmin()) next({ name: 'error-403' })

    if (isOperario(to) && !isUserOperario() && !isUserAdmin()) next({ name: 'error-403' })

    next()
  },
}
